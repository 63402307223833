import { composeBundles, createCacheBundle } from 'redux-bundler';

import cache from '@/utils/cache';

import routes from './routes';
import redirects from './redirects';
import extraArgs from './extraArgs';
import login from './login';
import appDrawer from './appDrawer';

import users from './users';

import toast from './toast';

export default composeBundles(
  createCacheBundle(cache.set),
  extraArgs,
  routes,
  redirects,
  login,
  appDrawer,
  toast,
  users,
);
