import { createElement, Component } from 'react';

const CONTEXT_TYPES = {
  store: () => {},
};

// HOC
export const connect = (...args) => {
  const Comp = args.slice(-1)[0];
  const strings = args.length > 1 ? args.slice(0, -1) : [];
  const actionCreators = [];
  const keysToWatch = [];
  strings.forEach((str) => {
    if (str.slice(0, 6) === 'select') {
      keysToWatch.push(str);
      return;
    }
    if (str.slice(0, 2) === 'do') {
      actionCreators.push(str);
      return;
    }
    throw Error(`CanNotConnect ${str}`);
  });

  class Connect extends Component {
    constructor(props, context) {
      super(props, context);
      const store = context.store;
      this.state = store.select(keysToWatch);
      this.unsubscribe = store.subscribeToSelectors(
        keysToWatch,
        this.setState.bind(this),
      );
      this.actionCreators = {};
      actionCreators.forEach((name) => {
        this.actionCreators[name] = (...args) => {
          if (store.action) {
            return store.action(name, args);
          }
          return store[name](...args);
        };
      });
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      return createElement(
        Comp,
        Object.assign({}, this.props, this.state, this.actionCreators),
      );
    }
  }
  Connect.contextTypes = CONTEXT_TYPES;

  return Connect;
};

// render-props
export class Connect extends Component {
  static contextTypes = CONTEXT_TYPES;

  constructor(props, context) {
    super(props, context);

    // get selectors & actions from props
    const strings = props.to || [];
    const actionCreators = [];
    const keysToWatch = [];
    strings.forEach((str) => {
      if (str.slice(0, 6) === 'select') {
        keysToWatch.push(str);
        return;
      }
      if (str.slice(0, 2) === 'do') {
        actionCreators.push(str);
        return;
      }
      throw Error(`CanNotConnect ${str}`);
    });

    const store = context.store;
    this.state = store.select(keysToWatch);
    this.unsubscribe = store.subscribeToSelectors(
      keysToWatch,
      this.setState.bind(this),
    );
    this.actionCreators = {};
    actionCreators.forEach((name) => {
      this.actionCreators[name] = (...args) => {
        if (store.action) {
          return store.action(name, args);
        }
        return store[name](...args);
      };
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { children, to, ...props } = this.props;
    return children(Object.assign({}, props, this.state, this.actionCreators));
  }
}
