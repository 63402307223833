// https://github.com/sindresorhus/is/blob/master/source/index.ts#L57
const getType = (value) => {
  const objectName = Object.prototype.toString.call(value).slice(8, -1);
  if (!objectName) {
    return undefined;
  }
  return objectName;
};

export default getType;
