/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */
import { connect } from '@/lib/redux-bundler-react';
import Aside from '@/modules/Aside';
import Header from '@/modules/Header';
import * as pages from '@/pages';
import cn from 'classnames';
import navHelper from 'internal-nav-helper';
import { ToastContainer } from 'react-toastify';
import styles from './App.module.scss';

const App = ({ doUpdateUrl, route }) => {
  let Page;
  if (route) {
    const pageName = route.name;
    Page = pages[pageName];
  } else {
    Page = () => null;
  }

  const isPublicPages = [
    'Login',
    'UserLogin',
    'DeActiveUserPage',
    'DeleteAccountInstruction',
  ]?.includes(route.name);

  return (
    <div
      className={cn(
        'font-sans leading-normal w-screen max-w-full relative min-h-screen',
        isPublicPages && 'bg-cover bg-center bg-no-repeat',
      )}
      onClick={navHelper(doUpdateUrl)}
      style={{
        background:
          'url(https://milaco.vn/wp-content/uploads/2018/10/website-login-background.jpg)',
      }}
    >
      {isPublicPages ? (
        <main className="flex h-full relative">
          <Page />
        </main>
      ) : (
        <div className="flex min-h-screen">
          <Aside />
          <div className="flex flex-col w-full">
            <Header />
            <main
              className={cn(
                styles['app-content'],
                'flex-auto bg-grey-lighter relative',
              )}
            >
              <Page />
            </main>
          </div>
        </div>
      )}
      <ToastContainer autoClose={5000} />
    </div>
  );
};

export default connect('selectRoute', 'selectToastState', 'doUpdateUrl', App);
