import React from 'react';

import { Connect } from '@/lib/redux-bundler-react';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
} from '@/components/mdc/TopAppBar';
import Icon from '@/components/Icon';

import { mainColorName } from '@/configs';

import styles from '@/App.module.scss';

const Header = () => (
  <TopAppBar
    fixed={false}
    className={`bg-${mainColorName}  relative ${styles['mdc-top-app-bar']}`}
  >
    <TopAppBarRow className="h-full flex">
      <div className="flex justify-between w-full col">
        <TopAppBarSection
          alignStart
          className={styles['mdc-top-app-bar__section'] + ' p-0'}
        >
          <Connect to={['doToggleAppDrawer']}>
            {({ doToggleAppDrawer }) => (
              <button
                onClick={doToggleAppDrawer}
                className="rounded hover:bg-butterfly-bus bg-transparent focus:outline-none flex justify-center items-center w-10 h-10"
              >
                <Icon
                  type="bars"
                  className="rounded-none text-white flex text-2xl font-semibold"
                />
              </button>
            )}
          </Connect>
        </TopAppBarSection>
        <TopAppBarSection
          alignEnd
          className={styles['mdc-top-app-bar__section'] + ' p-0'}
        >
          <Connect to={['doLogout']}>
            {({ doLogout }) => (
              <button
                onClick={() => doLogout()}
                className="rounded bg-butterfly-bus bg-transparent focus:outline-none flex justify-center items-center w-auto px-4 font-medium h-10"
              >
                Logout
              </button>
            )}
          </Connect>
        </TopAppBarSection>
      </div>
    </TopAppBarRow>
  </TopAppBar>
);

export default Header;
