import React from 'react';
import Loadable from 'react-loadable';
import NProgress from 'nprogress';
import '@/styles/nprogress.css';

NProgress.configure({ minimum: 0.1, speed: 200, showSpinner: false });

const LoadableCpn = (loader, opts = {}) =>
  Loadable({
    loader,
    loading: ({ isLoading, error, pastDelay, timedOut }) => {
      if (isLoading) {
        NProgress.start();
        return null;
      }
      NProgress.done();
      return null;
    },
    render(loaded, props) {
      NProgress.done();
      return <loaded.default {...props} />;
    },
    ...opts,
  });

export default LoadableCpn;
