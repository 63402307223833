import { toast } from 'react-toastify';

import apiUrls from '@/constants/apiUrls';

import fetch from '@/utils/fetch';
import { stringify } from '@/utils/helpers';

class CoreService {
  constructor({ store }) {
    this.store = store;
  }

  getToken = () =>
    this.store.selectUserLoginToken() || this.store.selectUserLoginTokenData();
  sessionInvalid = () => this.store.doLogout();
  redirects = () => this.store.doUpdateUrl({ pathname: '/not-allow' });

  fetch = (path = '/', method = 'GET', { body, params, ...opts } = {}) => {
    console.log('body', body);
    console.log('params', params);
    return fetch(`${process.env.CORE_API_ENDPOINT}${path}`, {
      method,
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${this.getToken()}`,
      },
      ...opts,
      body: body ? JSON.stringify(body) : null,
    }).then((resp) => {
      if (resp.status !== 200) {
        toast.error('Error');
      }

      return resp.json ? resp.json() : resp;
    });
  };

  // ====== USER FUNCS====== //
  doLogin = (body) => {
    return this.fetch(apiUrls.user.login, 'POST', { body });
  };

  doUserLogin = (body) => {
    return this.fetch(apiUrls.user.userLogin, 'POST', { body });
  };

  doDeActiveUser = (body = {}) => {
    return this.fetch(apiUrls.user.deActiveUser, 'DELETE', { body });
  };

  doLogout = (body) => {
    return this.fetch(apiUrls.user.logout, 'POST', { body });
  };

  fetchUsers = (params) => {
    return this.fetch(`${apiUrls.user.getUsers}?${stringify(params)}`);
  };

  fetchUserDetail = (id) => {
    return this.fetch(`${apiUrls.user.user}/${id}`);
  };

  doAddUser = (opts) => {
    return this.fetch(apiUrls.user.user, 'POST', opts);
  };

  doUpdateUser = (opts) => {
    return this.fetch(apiUrls.user.user, 'PUT', opts);
  };

  doDeleteUser = (id) => {
    return this.fetch(`${apiUrls.user.user}/${id}`, 'DELETE');
  };

  doUserForgotPassword = (opts) => {
    return this.fetch(apiUrls.user.forgotPassword, 'POST', opts);
  };

  doUserResetPassword = (opts) => {
    return this.fetch(apiUrls.user.resetPassword, 'POST', opts);
  };
}

export default CoreService;
