import { createRouteBundle, createSelector } from 'redux-bundler';

const bundle = createRouteBundle({
  '/': {
    name: 'Home',
  },
  '/login': {
    name: 'Login',
  },
  '/user-login': {
    name: 'UserLogin',
  },
  '/deactive': {
    name: 'DeActiveUserPage',
  },
  '/delete-account-instruction': {
    name: 'DeleteAccountInstruction',
  },
  '/users': {
    name: 'Users',
  },
  '/users?*': {
    name: 'Users',
  },
  '/user-detail/:id': {
    name: 'UserDetail',
  },
  '/not-allow': {
    name: 'NotAllow',
  },
  '*': {
    name: 'NotFound',
  },
});

bundle.selectRouteName = createSelector('selectRoute', (route) => route.name);

export default bundle;
