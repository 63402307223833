const apiUrls = {
  user: {
    login: '/user/login',
    userLogin: '/user/sign-in-mobile',
    deActiveUser: '/user/deactivate',
    logout: '/user/logout',
    getUsers: '/users',
    user: '/user',
    forgotPassword: '/user/forgot-password',
    resetPassword: '/user/reset-password',
  },
};

export default apiUrls;
