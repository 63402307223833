const initialState = {
  isShow: false,
  message: null,
  type: 'success',
};

const bundle = {
  name: 'toast',
  getReducer: () => {
    return (state = initialState, { type, payload }) => {
      if (type === 'UPDATE_TOAST_STATE') {
        return {
          ...state,
          ...(payload || {}),
        };
      }
      return state;
    };
  },
  selectToastState: (state) => state.toast,
  doUpdateToastState:
    (data) =>
    ({ dispatch, store }) => {
      if (data.isShow) {
        setTimeout(
          () => store.doUpdateToastState({ isShow: false, message: null }),
          1500,
        );
      }
      return dispatch({
        type: 'UPDATE_TOAST_STATE',
        payload: data,
      });
    },
};

export default bundle;
