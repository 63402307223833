import { createSelector } from 'redux-bundler';

const publicUrls = [
  '/login',
  '/user-login',
  '/deactive',
  '/delete-account-instruction',
];

export default {
  name: 'redirects',
  reactRedirects: createSelector(
    'selectPathname',
    'selectIsLoggedIn',
    (pathname, isLoggedIn) => {
      if (!isLoggedIn && !publicUrls.includes(pathname)) {
        return { actionCreator: 'doUpdateUrl', args: ['/login'] };
      }

      if (isLoggedIn && publicUrls.includes(pathname)) {
        return { actionCreator: 'doUpdateUrl', args: ['/'] };
      }
      // remove trailing slash
      if (pathname !== '/' && pathname.endsWith('/')) {
        return { actionCreator: 'doReplaceUrl', args: [pathname.slice(0, -1)] };
      }
    },
  ),
};
