import React from 'react';

import { Drawer, DrawerContent } from '@/components/mdc/Drawer';
import { ListItemText, ListItem } from '@/components/mdc/List';
import Link from '@/components/Link';

import { asideTitle, asideBgColorName } from '@/configs';

import { Connect } from '@/lib/redux-bundler-react';

import styles from '@/App.module.scss';

const Aside = () => (
  <>
    <Connect to={['selectAppDrawerOpen', 'selectInfoLogin']}>
      {({ appDrawerOpen, infoLogin }) => {
        const { profile = {} } = infoLogin;
        const { firstName = '', lastName = '', avatar = null } = profile;

        return (
          <>
            <Drawer
              persistent
              open={appDrawerOpen}
              className={styles['app-drawer']}
            >
              <DrawerContent className="p-0 min-h-full text-white bg-outer-space">
                <div
                  className={`bg-${asideBgColorName} flex justify-center items-center`}
                  style={{ height: '60px' }}
                >
                  <Link
                    className="text-2xl font-bold no-underline text-white"
                    href="/"
                  >
                    {asideTitle}
                  </Link>
                </div>
                <div className="flex items-center px-4 py-6">
                  {avatar ? (
                    <img
                      src={avatar}
                      alt=""
                      className="block w-12 h-12 rounded-full mr-4"
                    />
                  ) : (
                    <div className="w-12 h-12 rounded-full mr-4 border flex justify-center items-center bg-grey-dark">
                      {firstName.slice(0, 1)}
                    </div>
                  )}
                  <div>
                    <p className="text-white font-semibold text-lg">
                      {`${firstName} ${lastName}`}
                    </p>
                    <div className="text-sm flex items-center">
                      <div className="bg-green w-3 h-3 rounded-full mr-1" />
                      Online
                    </div>
                  </div>
                </div>
                <ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
                  <ListItemText className="text-xs">Manage Users</ListItemText>
                </ListItem>
                <ListItem className="p-0">
                  <Link
                    href="/users"
                    className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
                    activeClassName="bg-outer-space-light border-butterfly-bus"
                  >
                    Users
                  </Link>
                </ListItem>
                <ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
                  <ListItemText className="text-xs">
                    Digital Contents
                  </ListItemText>
                </ListItem>
                <ListItem className="p-0" ripple>
                  <Link
                    href="/digital-content-categories"
                    className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
                    activeClassName="bg-outer-space-light border-butterfly-bus"
                  >
                    Digital Content Categories
                  </Link>
                </ListItem>
                <ListItem className="p-0">
                  <Link
                    href="/digital-contents"
                    className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
                    activeClassName="bg-outer-space-light border-butterfly-bus"
                  >
                    Digital Contents
                  </Link>
                </ListItem>
                <ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
                  <ListItemText className="text-xs">Events</ListItemText>
                </ListItem>
                <ListItem className="p-0">
                  <Link
                    href="/events-categories"
                    className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
                    activeClassName="bg-outer-space-light border-butterfly-bus"
                  >
                    Events Categories
                  </Link>
                </ListItem>
                <ListItem className="p-0">
                  <Link
                    href="/events-contents"
                    className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
                    activeClassName="bg-outer-space-light border-butterfly-bus"
                  >
                    Events
                  </Link>
                </ListItem>
                <ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
                  <ListItemText className="text-xs">Settings</ListItemText>
                </ListItem>
                <ListItem className="p-0">
                  <Link
                    href="/system-settings"
                    className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
                    activeClassName="bg-outer-space-light border-butterfly-bus"
                  >
                    System Settings
                  </Link>
                </ListItem>
              </DrawerContent>
            </Drawer>
          </>
        );
      }}
    </Connect>
  </>
);

export default Aside;
