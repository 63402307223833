import loadable from '@/components/Loadable';
export const Login = loadable(() => import('@/pages/LoginPage'));
export const UserLogin = loadable(() => import('@/pages/UserLoginPage'));
export const DeActiveUserPage = loadable(() =>
  import('@/pages/DeActiveUserPage'),
);
export const DeleteAccountInstruction = loadable(() =>
  import('@/pages/DeleteAccountInstruction'),
);
export const Home = loadable(() => import('@/pages/HomePage'));
export const Users = loadable(() => import('@/pages/Users'));
export const UserDetail = loadable(() => import('@/pages/UserDetail'));
export const NotAllow = loadable(() => import('@/pages/NotAllow'));
export const NotFound = loadable(() => import('@/pages/NotFound'));
