import { toast } from 'react-toastify';

const bundle = {
  name: 'users',
  doFetchUsers:
    (params) =>
    ({ core }) => {
      return core.fetchUsers(params).then((res) => {
        if (res.code !== 200) {
          toast.error(res.message);
        }

        return res;
      });
    },
  doFetchUserDetail:
    () =>
    ({ core, store }) => {
      const routeParams = store.selectRouteParams();

      return core.fetchUserDetail(routeParams.id);
    },
  doAddAndUpdateUser:
    (params) =>
    ({ core }) => {
      if (params.isCreate) {
        return core.doAddUser({ body: params.data });
      }
      return core.doUpdateUser({ body: params.data });
    },
  doDeleteUser:
    (id) =>
    ({ core }) => {
      return core.doDeleteUser(id);
    },
  doUserForgotPassword:
    (data) =>
    ({ core }) => {
      return core.doUserForgotPassword({ body: data });
    },
  doUserResetPassword:
    (data) =>
    ({ core }) => {
      return core.doUserResetPassword({ body: data });
    },
};

export default bundle;
